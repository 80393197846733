import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Card from '../components/list/card';

export const pageQuery = graphql`
  query ListQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___published], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            published(locale: "de", formatString: "MMMM YYYY")
            title
            accent
            banner {
              id
              childImageSharp {
                fixed(toFormat: JPG, width: 640, height: 420) {
                  src
                  srcSet
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Posts = styled.div`
  display: grid;
  padding: var(--spacer);
  gap: var(--spacer);

  grid-template-columns: repeat(auto-fit, minmax(var(--card-size), 1fr));
`;

export default function ListTemplate({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />

      <svg viewBox="0 0 300 200" width="300px" height="200px" style={{ display: 'none' }}>
        <defs>
          <linearGradient id="fade-gradient" y2="1" x2="0">
            <stop offset="0.5" stopColor="white" stopOpacity="1" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>

          <mask id="fade-mask" maskContentUnits="objectBoundingBox">
            <rect width="1" height="1" fill="url(#fade-gradient)" />
          </mask>
        </defs>
      </svg>

      <Posts>
        {posts.map(({ node }) => {
          const { slug } = node.fields;
          const {
            banner, title, published, accent,
          } = node.frontmatter;
          const [, type] = slug.match(/^\/(\w+)\//);

          return (
            <Card
              key={slug}
              slug={slug}
              banner={banner}
              title={title || slug}
              published={published}
              type={type}
              accent={accent}
            />
          );
        })}
      </Posts>
    </Layout>
  );
}
