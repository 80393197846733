import dev from '../../assets/dev.svg';
import study from '../../assets/study.svg';
import photo from '../../assets/photo.svg';
import post from '../../assets/post.svg';

export default function getIcon(type) {
  switch (type) {
    case 'dev':
      return dev;
    case 'study':
      return study;
    case 'photo':
      return photo;
    default:
      return post;
  }
}
