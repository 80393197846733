import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import getCardIcon from '../../utils/getCardIcon';

const StyledCard = styled(Link)`
  border-radius: var(--border-radius);
  background-color: rgb(var(--bg-secondary));

  overflow: hidden;

  display: grid;

  grid-template-rows: 2fr 1fr min-content;
  grid-template-columns: 1fr;

  overflow: hidden;
  background: ${({ accent }) => accent}80;
  box-shadow: 0 8px 32px 0 ${({ accent }) => accent}5e;
  border: 0.1rem solid ${({ accent }) => accent}2e;
  border-radius: 0.5rem;
  backdrop-filter: blur(6.5px);

  .gatsby-image-wrapper {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    min-height: 360px;
  }
`;

const Header = styled.header`
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  text-align: center;
  position: relative;

  padding-bottom: 1rem;

  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  color: rgb(var(--text-primary));

  svg {
    width: 3rem;
    justify-self: center;
    border: 0.2rem solid rgb(var(--bg-primary));
    border-radius: 50%;
  }
`;

const StyledSvg = styled.svg`
    width: 100%;
    height: auto;
    grid-row: 1 / 3;
    grid-column: 1 / 2;

    picture {
      width: 100%;
      height: auto;
    }

    img {
      width: 100%;
    }
`;

const Title = styled.div`
  text-align: left;
  padding-right: 1rem;
`;

export default function Card({
  slug, banner, title, published, type, accent,
}) {
  const Icon = getCardIcon(type);

  return (
    <StyledCard to={slug} accent={accent}>
      <StyledSvg viewBox="0 0 640 420">
        <defs>
          <linearGradient id={`gradient-${banner.id}`} y2="1" x2="0">
            <stop offset="0.66" stopColor="white" stopOpacity="1" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>

          <mask id={`mask-${banner.id}`} maskContentUnits="objectBoundingBox">
            <rect width="1" height="1" fill={`url(#gradient-${banner.id})`} />
          </mask>
        </defs>
        <foreignObject
          width="640"
          height="420"
          mask={`url(#mask-${banner.id})`}
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <picture loading="lazy" xmlns="http://www.w3.org/1999/xhtml">
            <source
              type="image/webp"
              srcSet={banner.childImageSharp.fixed.srcSetWebp}
            />
            <source
              type="image/jpg"
              srcSet={banner.childImageSharp.fixed.srcSet}
            />
            <img loading="lazy" src={banner.childImageSharp.fixed.src} alt={title} />
          </picture>
        </foreignObject>
      </StyledSvg>

      <Header accent={accent}>
        <Icon />

        <Title>
          <h3>{title}</h3>
          <time>{published}</time>
        </Title>
      </Header>
    </StyledCard>
  );
}
